var $ = require("jquery");
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
// import { Fancybox } from "@fancyapps/ui";


/* ====================== FANCYBOX ======================  */

// document.addEventListener("DOMContentLoaded", function() {
//     Fancybox.bind("[data-fancybox='prod-gallery']", {
//         on: {
//             done: (fancybox, slide) => {
//                 const activeContentElement = document.querySelector(".fancybox__slide.is-selected .fancybox__content");

//                 if (activeContentElement) {
//                     // Clear any previous button to avoid duplicates
//                     activeContentElement.querySelector(".fancybox-buttons")?.remove();

//                     const buttonWrapper = document.createElement("div");
//                     buttonWrapper.className = "fancybox-buttons";

//                     // Explicitly get data-id from the current Fancybox trigger element
//                     const triggerElement = document.querySelector(`[data-fancybox='prod-gallery'][href="${slide.src}"]`);
//                     const imageId = triggerElement ? triggerElement.getAttribute("data-id") : null;

//                     const wishlistButton = document.createElement("button");
//                     wishlistButton.className = "quick-wishlist";
//                     wishlistButton.innerHTML = `
//                         <svg xmlns="http://www.w3.org/2000/svg" width="19.177" height="17.566" viewBox="-2 -2 23.177 21.566">
//                             <g id="heart" transform="translate(0 -8.063)">
//                                 <path id="Path_6515" data-name="Path 6515" d="M18.8,11.259a8.266,8.266,0,0,1-1.394,7.493,21.207,21.207,0,0,1-3.465,3.659c-1.14,1.061-3.691,3.16-4.367,3.219a5.54,5.54,0,0,1-1.743-1.138C5.17,22.465,2.3,20,.845,17.306c-1.22-2.587-1.222-5.786.677-7.77a5.613,5.613,0,0,1,8.057.533,5.45,5.45,0,0,1,1.866-1.548A5.613,5.613,0,0,1,18.8,11.259Z" transform="translate(0)" fill="transparent" stroke="#031c44" stroke-width="2"/>
//                             </g>
//                         </svg>
//                         <span>Add to Wishlist</span>
//                     `;
                    
//                     if (imageId) {
//                         wishlistButton.setAttribute("data-image-id", imageId);
//                     }

//                     buttonWrapper.appendChild(wishlistButton);
//                     activeContentElement.appendChild(buttonWrapper);

//                     wishlistButton.addEventListener("click", function() {
//                         const imageId = wishlistButton.getAttribute("data-image-id");
//                         if (imageId) toggleWishlist(imageId);
//                         sendWishlistToServer();
//                     });

//                     updateWishlistButtons();
//                 }
//             }
//         }
//     });

//     function getWishlist() {
//         return JSON.parse(localStorage.getItem('wishlist')) || [];
//     }

//     function setWishlist(wishlist) {
//         localStorage.setItem('wishlist', JSON.stringify(wishlist));
//     }

//     function updateWishlistCounter() {
//         const wishlist = getWishlist();
//         const counter = wishlist.length;
//         document.querySelectorAll('.wishlist-counter').forEach(el => el.textContent = counter);
//     }

//     function updateWishlistButtons() {
//         const wishlist = getWishlist();

//         document.querySelectorAll('.quick-wishlist').forEach(button => {
//             const imageId = button.getAttribute('data-image-id');
//             if (wishlist.includes(imageId)) {
//                 button.classList.add('in-wishlist');
//                 button.querySelector('span').textContent = 'Remove from Wishlist';
//             } else {
//                 button.classList.remove('in-wishlist');
//                 button.querySelector('span').textContent = 'Add to Wishlist';
//             }
//         });
//     }

//     function toggleWishlist(imageId) {
//         let wishlist = getWishlist();

//         if (wishlist.includes(imageId)) {
//             wishlist = wishlist.filter(id => id !== imageId);
//         } else {
//             wishlist.push(imageId);
//             animateWishlistIcon();
//         }

//         setWishlist(wishlist);
//         updateWishlistButtons();
//         updateWishlistCounter();
//     }

//     function animateWishlistIcon() {
//         const icon = document.querySelector('.header-top__wishlist i');
//         if (icon) {
//             icon.classList.add('wishlist-animated');
//             setTimeout(() => icon.classList.remove('wishlist-animated'), 400);
//         }
//     }

//     function sendWishlistToServer() {
//         const wishlist = getWishlist();

//         fetch(ajax_params.ajax_url, {
//             method: 'POST',
//             headers: { 'Content-Type': 'application/json' },
//             body: JSON.stringify({
//                 action: 'load_wishlist',
//                 wishlist_items: wishlist
//             })
//         })
//         .then(response => response.text())
//         .then(html => {
//             document.querySelector('.wishlist__images').innerHTML = html;
//         })
//         .catch(error => console.error('AJAX Error:', error));
//     }

//     updateWishlistCounter();
//     updateWishlistButtons();
// });



